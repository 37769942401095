import { Component, OnInit } from '@angular/core';
import { AuthenticationService, SnackBarService } from '@app/shared/services';
import { EventModel } from '@app/events/events-form/event.model'
import { EventsFormComponent } from './events-form/events-form.component';
import { MatDialog, MatDialogConfig, MatDialogRef } from '@angular/material/dialog';
import { DataArrayService } from './events.service';
import { EventsEditFormComponent } from './events-edit-form/events-edit-form.component';
import { DataStorageService } from '@app/shared/services/data-storage.service';
import esJson from '../../assets/i18n/es.json';
import enJson from '../../assets/i18n/en.json';
import { ConfirmationDialogComponent } from '@app/shared/components/confirmation-dialog/confirmation-dialog.component';
import { ActivatedRoute, Router } from '@angular/router';
import { DashboardService } from '@app/dashboard/dashboard.service';
import { ProductsService } from '@app/products/products.service';
import { VoucherService } from '@app/voucher/voucher.service';
import { RoomsService } from '@app/rooms/rooms.service';

@Component({
  selector: 'app-events',
  templateUrl: './events.component.html',
  styleUrls: ['./events.component.scss']
})

export class EventsComponent implements OnInit {
  langJson = (localStorage.getItem('lang') === 'es') ? esJson : enJson;

  buttons_filter = [{id: 'boton_asc_name', contenido: this.langJson.events.ascFilter}, {id: 'boton_desc_name', contenido: this.langJson.events.ascFilter}];
  abrirFormulario = false;

  dataArray: string[];
  data: any;
  eventId: any;
  popupMsg: string;


  constructor(
    public authService: AuthenticationService,
    public dialog: MatDialog,
    private router: Router,
    private route: ActivatedRoute,
    private snackBarService: SnackBarService,
    private dataArrayService: DataArrayService,
    public dataStorageService: DataStorageService,
    public dashboardService: DashboardService,
    private productsService: ProductsService,
    private vouchersService: VoucherService,
    private roomsService: RoomsService
  ) { }

  ngOnInit(): void {
    this.authService.setLoader(false);
    this.dataStorageService.fetchEvents().subscribe(response => {
      this.data = response;
      this.data = this.data.sort((a, b) => {
        return (a.name.toLowerCase()).localeCompare((b.name.toLowerCase()), undefined, {numeric: true, sensitivity: 'base'});
      });
      this.filteredData = this.data.slice();
      this.dataArrayService.setData(this.data);
    });

    if (localStorage.getItem('lang') === 'es') {
      this.popupMsg = '¿Quiere iniciar sesión en este evento?'

    }else{
      this.popupMsg = 'Do you want to log in to this event?'
    }
  }


  flag: boolean = false;
  botonActivo: any;
  orden_ascendente = true;

  activarBoton(button: any) {
    if (!this.flag) {
      this.botonActivo = document.getElementById('boton_asc_name');
    }

    if (this.botonActivo) {
      this.botonActivo.classList.remove("clicked");
    }

    this.botonActivo = button;
    button.classList.add("clicked");
    this.flag = true;

    if (button.id === this.buttons_filter[1].id) {
      this.orden_ascendente = false;
      this.data = this.data.sort((a, b) => {
        return (b.name.toLowerCase()).localeCompare((a.name.toLowerCase()), undefined, {numeric: true, sensitivity: 'base'});
      });
      this.filteredData = this.filteredData.sort((a, b) => {
        return (b.name.toLowerCase()).localeCompare((a.name.toLowerCase()), undefined, {numeric: true, sensitivity: 'base'});
      });
    } else if (button.id === this.buttons_filter[0].id) {
      this.orden_ascendente = true; //!!!!!!
      this.data = this.data.sort((a, b) => {
        return (a.name.toLowerCase()).localeCompare((b.name.toLowerCase()), undefined, {numeric: true, sensitivity: 'base'});
      });
      this.filteredData = this.filteredData.sort((a, b) => {
        return (a.name.toLowerCase()).localeCompare((b.name.toLowerCase()), undefined, {numeric: true, sensitivity: 'base'});
      });
    }

  }

  hidden = false;

  buscador(){
    this.hidden = !this.hidden;
  }

  filteredData = [];
  searchValue = '';

  filtro_buscador(){
    if (this.searchValue === '') {
      this.filteredData = this.data.slice();
    } else {
    this.filteredData = this.data.filter(data_filtrado => data_filtrado.name.toLowerCase().includes(this.searchValue.toLowerCase()));
    }
  }

  abrirFormularioPopup() {
    const dialogConfig = new MatDialogConfig();
    dialogConfig.maxWidth = '95vw';
    dialogConfig.width = '95vw';
    dialogConfig.maxHeight = '95vh';
    dialogConfig.height = '95vh';

    const dialog_evento = this.dialog.open(EventsFormComponent, dialogConfig);
    dialog_evento.afterClosed().subscribe(data_local => {
      if (data_local!=undefined) {
        this.actualizar_lista(data_local);
      }
    });
  }

  actualizar_lista(value: any){
    this.data.push(value);
    this.filteredData.push(value);

    if (!this.orden_ascendente) {
      this.data = this.data.sort((a, b) => {
        return (b.name.toLowerCase()).localeCompare((a.name.toLowerCase()), undefined, {numeric: true, sensitivity: 'base'});
      });
      this.filteredData = this.filteredData.sort((a, b) => {
        return (b.name.toLowerCase()).localeCompare((a.name.toLowerCase()), undefined, {numeric: true, sensitivity: 'base'});
      });
    } else if (this.orden_ascendente) {
      this.data = this.data.sort((a, b) => {
        return (a.name.toLowerCase()).localeCompare((b.name.toLowerCase()), undefined, {numeric: true, sensitivity: 'base'});
      });
      this.filteredData = this.filteredData.sort((a, b) => {
        return (a.name.toLowerCase()).localeCompare((b.name.toLowerCase()), undefined, {numeric: true, sensitivity: 'base'});
      });
    }
  }



  public posicion = 0;
  valor_nombre_pred = "";
  valor_attendees_pred = "";
  valor_fecha_inicio_pred: any;
  valor_fecha_fin_pred: any;
  valor_has_vouchers_pred: any;
  valor_control_accesos_pred: any;
  data_form: any;

  abrirFormularioEditPopup(evento: any) {
    this.posicion = this.data.indexOf(evento);
    this.valor_nombre_pred = evento.name;
    this.valor_attendees_pred = evento.attendees;
    this.valor_fecha_inicio_pred = evento.startingDate;
    this.valor_fecha_fin_pred = evento.endingDate;
    this.valor_has_vouchers_pred = evento.hasVouchers;
    this.valor_control_accesos_pred = evento.hasAccessControl;
    this.dataArrayService.setEventClicked(evento);
    this.data_form = evento;

    const dialogConfig = new MatDialogConfig();
    dialogConfig.maxWidth = '95vw';
    dialogConfig.width = '95vw';
    dialogConfig.maxHeight = '95vh';
    dialogConfig.height = '95vh';
    dialogConfig.data = this.data_form;

    const dialog_evento = this.dialog.open(EventsEditFormComponent, dialogConfig);
    dialog_evento.afterClosed().subscribe(data_local => {
      if (data_local != undefined) {
        this.actualizar_evento_lista(data_local, this.posicion);
        window.location.reload();
      }
    });
  }

  goToEvent(evento:any){
    this.eventId = evento.id;
    const dialogRef2 = this.dialog.open(ConfirmationDialogComponent, {
      width: '350px',
      data: this.popupMsg
    });
    dialogRef2.afterClosed().subscribe(result => {
        if (result) {
        this.snackBarService.openSnackBar('Redirigiendo a dashboard de este evento...');
        localStorage.setItem('roleUser', JSON.stringify('Administrador'));
        localStorage.setItem('eventId', JSON.stringify(this.eventId));
        const products = [];
        this.productsService.setProducts(products);
        const vouchers = [];
        this.vouchersService.setVouchers(vouchers);
        const rooms = [];
        this.roomsService.setRooms(rooms);
        this.router.navigate([`/dashboard`], {relativeTo: this.route});
        }
      });
  }




  actualizar_evento_lista(value: any, posicion: number){
    if (this.dataArrayService.delete_event_state === "actualizar_evento"){
      this.data[posicion] = value;
      this.filteredData[posicion] = value;
    } else {
      this.data.splice(posicion, 1);
      this.filteredData.splice(posicion, 1);
    }

    if (!this.orden_ascendente) {
      this.data = this.data.sort((a, b) => {
        return (b.name.toLowerCase()).localeCompare((a.name.toLowerCase()), undefined, {numeric: true, sensitivity: 'base'});
      });
      this.filteredData = this.filteredData.sort((a, b) => {
        return (b.name.toLowerCase()).localeCompare((a.name.toLowerCase()), undefined, {numeric: true, sensitivity: 'base'});
      });
    } else if (this.orden_ascendente) {
      this.data = this.data.sort((a, b) => {
        return (a.name.toLowerCase()).localeCompare((b.name.toLowerCase()), undefined, {numeric: true, sensitivity: 'base'});
      });
      this.filteredData = this.filteredData.sort((a, b) => {
        return (a.name.toLowerCase()).localeCompare((b.name.toLowerCase()), undefined, {numeric: true, sensitivity: 'base'});
      });
    }

  }

}

