import { Component, HostListener, OnInit } from '@angular/core';
import { AuthenticationService } from '@app/shared/services';
import { NotificationsDialogComponent } from './notifications-dialog/notifications-dialog.component';
import { MatDialog, MatDialogConfig } from '@angular/material/dialog';
import { DataStorageService } from '@app/shared/services/data-storage.service';
import { saveAs } from 'file-saver';
import esJson from '../../assets/i18n/es.json';
import enJson from '../../assets/i18n/en.json';
import { FormControl, FormGroup } from '@angular/forms';
import { InvitationsDialogComponent } from '@shared/components/invitations-dialog/invitations-dialog.component';

@Component({
  selector: 'app-notifications',
  templateUrl: './notifications.component.html',
  styleUrls: ['./notifications.component.scss']
})
export class NotificationsComponent implements OnInit {

  langJson = (localStorage.getItem('lang') === 'es') ? esJson : enJson;
  type_of_notification: { name: any; number_notifications: string; }[];
  getScreenWidth: number;
  getScreenHeight: number;
  eventId: any;

  constructor(
    public authService: AuthenticationService,
    public dialog: MatDialog,
    public dataStorageService: DataStorageService,
    private authenticationService: AuthenticationService  ) {}

  // startingDate: Date = new Date().toLocaleDateString('en-CA') + 'T08:00:00';
  startingDate: any;
  endDate: any;
  respuesta_notifications: any;

  ngOnInit(): void {
    this.authService.setLoader(true);
    this.getScreenWidth = window.innerWidth;
    this.getScreenHeight = window.innerHeight;
    this.startingDate = new Date().toLocaleDateString('en-CA') + ' 00:00:01';
    this.endDate = new Date().toLocaleDateString('en-CA') + ' 23:59:59';
    this.cargar_subjects(this.startingDate, this.endDate);
    this.eventId = JSON.parse(localStorage.getItem('eventId'));
    this.authService.setLoader(false);
  }


  cargar_subjects(startingDate: any, endDate: any){
    this.startingDate = startingDate;
    this.endDate = endDate;
    this.type_of_notification = [];
    this.dataStorageService.fetchSubjects(startingDate, endDate).subscribe(response => {
      this.respuesta_notifications = response.data
    })

  }



  fecha_desde: any;
  fecha_hasta: any;



  notification_type_name = '';

  openNotificationsDialogPopup(subject_event: any) {

    const dialogConfig = new MatDialogConfig();
    dialogConfig.maxWidth = '95vw';
    dialogConfig.width = '95vw';
    dialogConfig.maxHeight = '95vh';
    dialogConfig.height = '95vh';
    dialogConfig.data = {
      title: subject_event,
      inicio: this.startingDate,
      fin: this.endDate
    };
    this.dialog.open(NotificationsDialogComponent, dialogConfig)

  }

  usersToExcel() {
    this.authenticationService.setLoader(true);
    const filename = 'ListadoNotificacionesUsuarios.xlsx'
    this.authenticationService.setLoader(true);
    this.dataStorageService.downloadExcel('exportInfoTicketsInditex').subscribe((response: any) => {
      const blob: any = new Blob([response], { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8; charset=utf-8' });
      saveAs(blob, filename);
      this.authenticationService.setLoader(false);
    }, error => console.log('Ha ocurrido un error descargando el archivo', error),
      () => console.log('Archivo descargado con exito'));
  } // c65bf93b-c7cf-4801-b574-0a6dc8420528 && 4e2c719b-805c-4dcf-b4da-a619bf6ec759


  @HostListener('window:resize', ['$event'])
  onWindowResize() {
    this.getScreenWidth = window.innerWidth;
    this.getScreenHeight = window.innerHeight;
  }

  goPopUpSend() {
    const dialogRef2 = this.dialog.open(InvitationsDialogComponent, {
      width: '95vw',
      height: '95vh',
      data: 'Por favor, suba aquí sus archivos'
    });
    dialogRef2.afterClosed().subscribe(_result => {
    });
  }
}
