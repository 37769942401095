<mat-dialog-content>

  <div class="contenedor_cabecera_completa">
    <div class="contenedor_cabecera" style="width: 100% !important; display: flex !important; justify-content: center !important">
      <div class="cabecera" style="width: 95% !important;">
        {{data_notif.title}}
      </div>


    </div>
    <div class="contenedor_cabecera">
      <div *ngIf="getScreenWidth >= 500" class = "contenedor_cancel"></div>
      <div *ngIf="enviadas" class="cabecera" style="background-color: #18a1b9;" (click)="tipo_notif('enviadas'); cargar_notificaciones()"><p style=" color: white !important">{{langJson.notifications.sent}}</p></div>
      <div *ngIf="!enviadas" class="cabecera" (click)="tipo_notif('enviadas'); cargar_notificaciones()"><p>{{langJson.notifications.sent}}</p></div>
      <div style="width: 3px"></div>
      <div *ngIf="abiertas" class="cabecera" style="background-color: #18a1b9;" (click)="tipo_notif('abiertas'); cargar_notificaciones()"><p style=" color: white !important">{{langJson.notifications.opened}}</p></div>
      <div *ngIf="!abiertas" class="cabecera" (click)="tipo_notif('abiertas'); cargar_notificaciones()"><p>{{langJson.notifications.opened}}</p></div>
      <div style="width: 3px"></div>
      <div *ngIf="programadas" class="cabecera" style="background-color: #18a1b9;" (click)="tipo_notif('programadas'); cargar_notificaciones()"><p style=" color: white !important">{{langJson.notifications.scheduled}}</p></div>
      <div *ngIf="!programadas" class="cabecera" (click)="tipo_notif('programadas'); cargar_notificaciones()"><p>{{langJson.notifications.scheduled}}</p></div>
      <div style="width: 3px"></div>
      <div *ngIf="fallidas" class="cabecera" style="background-color: #18a1b9;" (click)="tipo_notif('fallidas'); cargar_notificaciones()"><p style=" color: white !important">{{langJson.notifications.failed}}</p></div>
      <div *ngIf="!fallidas" class="cabecera" (click)="tipo_notif('fallidas'); cargar_notificaciones()"><p>{{langJson.notifications.failed}}</p></div>
      <div class="contenedor_cancel" >
        <button>
            <mat-icon (click)="onClose()"
                svgIcon="close">
            </mat-icon>
        </button>
      </div>
    </div>



    <div class="contenedor_buscador">
      <mat-form-field class="example-full-width">
        <mat-label>{{ 'notifications.search' | translate }}...</mat-label>
        <input matInput id="barra_busqueda" type="text" [(ngModel)]="searchValue" (input) = cargar_notificaciones() >
      </mat-form-field>
      <span *ngIf="searchValue != ''" >
        <mat-icon class="close_style" (click)="searchValue=''; cargar_notificaciones()">close</mat-icon>
      </span>
    </div>

    <div style="display: flex !important; justify-content: left; align-items: center; padding: 0px 24px 0px 24px">
      <div style="width: 40px !important; height: 40px !important; display: flex !important; justify-content: start; align-items: center">
        <mat-icon *ngIf="!flag_icon_color"  (click)="click_icon_filtro()" style="cursor: pointer;">filter_list</mat-icon>
        <mat-icon *ngIf="flag_icon_color" class="color_icon" (click)="click_icon_filtro()" style="cursor: pointer;">filter_list</mat-icon>
      </div>
      <span *ngIf="flag_icon && flag_filtro ==='email'" class="boton_filtro_on" (click)="flag_filtros('email'); cargar_notificaciones()">Email</span>
      <span *ngIf="flag_icon && flag_filtro !=='email'" class="boton_filtro_off" (click)="flag_filtros('email'); cargar_notificaciones()">Email</span>
      <span *ngIf="flag_icon && flag_filtro ==='push'" class="boton_filtro_on" (click)="flag_filtros('push'); cargar_notificaciones()">Push</span>
      <span *ngIf="flag_icon && flag_filtro !=='push'" class="boton_filtro_off" (click)="flag_filtros('push'); cargar_notificaciones()">Push</span>
      <span *ngIf="flag_icon && flag_filtro ==='sms'" class="boton_filtro_on" (click)="flag_filtros('sms'); cargar_notificaciones()">SMS</span>
      <span *ngIf="flag_icon && flag_filtro !=='sms'" class="boton_filtro_off" (click)="flag_filtros('sms'); cargar_notificaciones()">SMS</span>
    </div>
    <div style="display: flex !important; justify-content: left; align-items: center; padding: 0px 24px 0px 24px">

      <mat-form-field *ngIf="flag_icon" style="width: 140px !important;">
        <mat-label>Desde</mat-label>
        <input matInput type="datetime-local" [(ngModel)]="fecha_desde"
        style="border-left: none; border-right: none; border-top: none; border-bottom-width: 1px; font-size: 13px; height: 20px; width: 140px; background-color: transparent;">
      </mat-form-field>

      <div style="width: 5px !important"></div>
      <mat-form-field *ngIf="flag_icon" style="width: 140px !important;">
        <mat-label>Hasta</mat-label>
        <input matInput type="datetime-local" [(ngModel)]="fecha_hasta"
        style="border-left: none; border-right: none; border-top: none; border-bottom-width: 1px; font-size: 13px; height: 20px; width: 140px; background-color: transparent;">
      </mat-form-field>
      <mat-icon *ngIf="flag_icon && fecha_desde<=fecha_hasta" style="padding-left: 5px;" (click)="cargar_notificaciones()">check</mat-icon>

    </div>

    </div>

  <div style="display: flex; align-items: center; justify-content: center; padding-bottom: 10px;">
    <span style="font-size: 18px; font-weight: bold; justify-content: center; text-align: center;">Total: {{filteredData.length}}</span>
  </div>

  <div *ngIf="eventId !== 'c65bf93b-c7cf-4801-b574-0a6dc8420528' && eventId !== '4e2c719b-805c-4dcf-b4da-a619bf6ec759' && filteredData.length > 0" class="contenedor_notificaciones"  style="overflow: auto">
    <div class="notificacion" *ngFor="let elemento_evento_filtrado of filteredData">
      <div class="foto_title">
        <mat-icon>contact_mail</mat-icon>
        <p>
            {{elemento_evento_filtrado.email}}
        </p>
        <!-- <div class="date">
          <p *ngIf="elemento_evento_filtrado.isEmail && getScreenWidth>800" style="width:30vw !important ; max-width: 30vw !important">
              Via Email <br> {{(elemento_evento_filtrado.updated_at === null) ? elemento_evento_filtrado.createdAt : elemento_evento_filtrado.updated_at }}
          </p>
          <p *ngIf="elemento_evento_filtrado.isPush && getScreenWidth>800" style="width:30vw !important ; max-width: 30vw !important">
            Via Push <br> {{(elemento_evento_filtrado.updated_at === null) ? elemento_evento_filtrado.createdAt : elemento_evento_filtrado.updated_at }}
          </p>
          <p *ngIf="elemento_evento_filtrado.isSMS && getScreenWidth>800" style="width:30vw !important ; max-width: 30vw !important">
            Via SMS <br> {{(elemento_evento_filtrado.updated_at === null) ? elemento_evento_filtrado.createdAt : elemento_evento_filtrado.updated_at }}
          </p>
        </div> -->
      </div>
      <div *ngIf="abiertas" class="date" style="min-width: fit-content">
          <p *ngIf="elemento_evento_filtrado.isEmail && getScreenWidth>650">Via Email <br> {{(elemento_evento_filtrado.opened_at === null) ? elemento_evento_filtrado.createdAt : elemento_evento_filtrado.opened_at }}</p>
          <p *ngIf="elemento_evento_filtrado.isPush && getScreenWidth>650">Via Push <br> {{(elemento_evento_filtrado.opened_at_at === null) ? elemento_evento_filtrado.createdAt : elemento_evento_filtrado.opened_at }}</p>
          <p *ngIf="elemento_evento_filtrado.isSMS && getScreenWidth>650">Via SMS <br> {{(elemento_evento_filtrado.opened_at_at === null) ? elemento_evento_filtrado.createdAt : elemento_evento_filtrado.opened_at }}</p>
      </div>
      <div *ngIf="!abiertas" class="date" style="min-width: fit-content">
        <p *ngIf="elemento_evento_filtrado.isEmail && getScreenWidth>650">Via Email <br> {{elemento_evento_filtrado.createdAt}}</p>
        <p *ngIf="elemento_evento_filtrado.isPush && getScreenWidth>650">Via Push <br> {{elemento_evento_filtrado.createdAt}}</p>
        <p *ngIf="elemento_evento_filtrado.isSMS && getScreenWidth>650">Via SMS <br> {{elemento_evento_filtrado.createdAt}}</p>
    </div>
    </div>

  </div>
  <div *ngIf="eventId === 'c65bf93b-c7cf-4801-b574-0a6dc8420528' || eventId === '4e2c719b-805c-4dcf-b4da-a619bf6ec759' && filteredData.length > 0" class="contenedor_notificaciones"  style="overflow: auto">
    <div  *ngFor="let elemento_evento_filtrado of filteredData" style="width: 100%;">
      <div class="notificacion" (click)="openDownloadTicketPDF(elemento_evento_filtrado.email)" style="cursor: pointer">
        <div class="foto_title">
          <mat-icon>contact_mail</mat-icon>
          <p>
              {{elemento_evento_filtrado.email}}
          </p>
          <!-- <div class="date">
            <p *ngIf="elemento_evento_filtrado.isEmail && getScreenWidth>800" style="width:30vw !important ; max-width: 30vw !important">
                Via Email <br> {{(elemento_evento_filtrado.updated_at === null) ? elemento_evento_filtrado.createdAt : elemento_evento_filtrado.updated_at }}
            </p>
            <p *ngIf="elemento_evento_filtrado.isPush && getScreenWidth>800" style="width:30vw !important ; max-width: 30vw !important">
              Via Push <br> {{(elemento_evento_filtrado.updated_at === null) ? elemento_evento_filtrado.createdAt : elemento_evento_filtrado.updated_at }}
            </p>
            <p *ngIf="elemento_evento_filtrado.isSMS && getScreenWidth>800" style="width:30vw !important ; max-width: 30vw !important">
              Via SMS <br> {{(elemento_evento_filtrado.updated_at === null) ? elemento_evento_filtrado.createdAt : elemento_evento_filtrado.updated_at }}
            </p>
          </div> -->
        </div>
        <div *ngIf="abiertas" class="date" style="min-width: fit-content">
            <p *ngIf="elemento_evento_filtrado.isEmail && getScreenWidth>650">Via Email <br> {{(elemento_evento_filtrado.opened_at === null) ? elemento_evento_filtrado.createdAt : elemento_evento_filtrado.opened_at }}</p>
            <p *ngIf="elemento_evento_filtrado.isPush && getScreenWidth>650">Via Push <br> {{(elemento_evento_filtrado.opened_at_at === null) ? elemento_evento_filtrado.createdAt : elemento_evento_filtrado.opened_at }}</p>
            <p *ngIf="elemento_evento_filtrado.isSMS && getScreenWidth>650">Via SMS <br> {{(elemento_evento_filtrado.opened_at_at === null) ? elemento_evento_filtrado.createdAt : elemento_evento_filtrado.opened_at }}</p>
        </div>
        <div *ngIf="!abiertas" class="date" style="min-width: fit-content">
          <p *ngIf="elemento_evento_filtrado.isEmail && getScreenWidth>650">Via Email <br> {{elemento_evento_filtrado.createdAt}}</p>
          <p *ngIf="elemento_evento_filtrado.isPush && getScreenWidth>650">Via Push <br> {{elemento_evento_filtrado.createdAt}}</p>
          <p *ngIf="elemento_evento_filtrado.isSMS && getScreenWidth>650">Via SMS <br> {{elemento_evento_filtrado.createdAt}}</p>
        </div>
      </div>
    </div>

  </div>
  <div *ngIf="spinner === true">
    <div class="spinner" style="max-height: calc(30vh);">
      <div class="lds-roller">
        <div></div><div></div><div></div><div></div><div></div><div></div><div></div><div></div>
      </div>
      <h6 style="color:#0F223E;font-size:30px">{{ 'loading' | translate }}</h6>
    </div>
  </div>
  <div *ngIf="filteredData.length == 0">
    <div class="spinner" style="max-height: calc(30vh);">
      <h6 style="color:#0F223E;font-size:30px">{{ 'notifications.noNotif' | translate }}</h6>
    </div>
  </div>


</mat-dialog-content>
